'use strict';

angular.module('managerApp').config(($routeProvider) => {
    $routeProvider.when('/help', {
        templateUrl: 'app/help/list.html',
        controller: 'HelpController',
        authenticate: 'user',
        hasPermissions: ['HELP:GET']
    }).when('/help/sliders', {
        templateUrl: 'app/help/sliders/list.html',
        controller: 'SlidersController',
        authenticate: 'user',
        hasPermissions: ['HELP:GET']
    }).when('/help/sliders/:id', {
        templateUrl: 'app/help/sliders/item.html',
        controller: 'SlidersController',
        authenticate: 'user',
        hasPermissions: ['HELP:GET']
    });
});